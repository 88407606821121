import {
  Box,
  Button,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { customStyle, selectProgramChip } from "../../../Style/style";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import {
  MentorDrawerInterface,
  MentorRequiredObj,
} from "../../../Interface/Interface";
import { ErrorMessage, FastField, Form, Formik } from "formik";
import { validationMentorSchema } from "../../../Schema/Schema";
import { useDispatch } from "react-redux";
import { openAlert } from "../../../redux/slices/snackbarSlice";
import InputCustom from "../../../components/reusables/InputCustom";
import ErrorText from "../../../components/reusables/ErrorText";
import {
  useAddMentorsMutation,
  useGetAllBatchesQuery,
  useGetProgramsListQuery,
} from "../../../redux/api/api";
import Buttons from "../../../components/reusables/Buttons";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Theme, useTheme } from "@mui/material/styles";
import { SELECT_PROGRAM } from "../../../components/utils/learnerDrawerConstant";

const selectStyleBox = {
  height: "56px",
  backgroundColor: "var(--lightGray)",
  border: "1px solid var(--borderInput)",
  fontFamily: "var(--fontFamiljen)",
  lineHeight: "20px",
  color: "var(--InputTextGray)",
  padding: "14px 6px",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "16px",
};

const labelText = {
  fontFamily: "Familjen Grotesk",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "18px",
  color: "#544F4F",
  marginBottom: "8px",
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 212,
    },
  },
};

function getStyles(name: string, theme: Theme) {
  return {
    fontWeight: theme.typography.fontWeightMedium,
    height: "37px",
    fontSize: "14px",
    fontFamily: "var(--fontFamiljen)",
    color: "var(--activityText)",
  };
}

const MentorDrawer = ({
  setUpdateList,
  updateList,
  singleMentorDetail,
  onHide,
}: MentorDrawerInterface) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [validateMentor, setValidateMentor] = useState<boolean>(false);
  const [alreadySelectedProgram, setAlreadySelectedProgram] = useState<any>("");
  const [programId, setProgramId] = useState<any>("");

  const { data: techStackData } = useGetProgramsListQuery({
    limit: 1000,
    isPublished: true,
  });

  const batchesPayload = {
    url: "program-batches",
    program_id: programId,
    mentor_assigned: false,
  };
  const {
    data: allBatches,
    isLoading: batchIsLoading,
    isFetching: batchIsFetching,
  } = useGetAllBatchesQuery(batchesPayload, {
    skip: programId === SELECT_PROGRAM || programId === "",
  });

  
  const programIds = singleMentorDetail && singleMentorDetail?.programs_with_batches?.map(
    (item: any) => {
      return {
        program_id: item?.program_id,
        batch_id: {
          id: item?.batch_id,
          name: item?.batch_name,
        },
      };
    }
  );

  const [inputFields, setInputFields] = useState<any>(
    programIds || [
      {
        temp: "temp",
        program_id: "",
        batch_id: {
          id: "",
          name: "",
        },
      },
    ]
  );

  const handleAddFields = () => {
    const values = [...inputFields];
    values.push({
      temp: "temp",
      program_id: "",
      batch_id: {
        id: "",
        name: "",
      },
    });
    setInputFields(values);
    setValidateMentor(false);
    setAlreadySelectedProgram("");
  };

  const handleRemoveFields = (index: any) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
    setAlreadySelectedProgram("");
  };

  const handleInputChange = (index: any, event: any) => {
    const values = [...inputFields];
    if (event.target.name === "program_id") {
      values[index].program_id = event.target.value;
    } else {
      values[index].batch_id.id = event.target.value._id;
      values[index].batch_id.name = event.target.value.name;
    }

    setInputFields(values);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
  };

  const [addMentors, { isLoading }] = useAddMentorsMutation();

  const initialValues = {
    first_name: singleMentorDetail?.first_name || "",
    last_name: singleMentorDetail?.last_name || "",
    email: singleMentorDetail?.email || "",
  };

  const handleSubmitForm = async (values: any) => {
    const onlyIds = inputFields
      ?.filter((item: any) => 'temp' in item) // Filter objects that have the 'temp' key
      .map((item: any) => ({
        program_id: item?.program_id,
        batch_id: item?.batch_id?.id,
      }));

    const repeats = inputFields?.map((it: any) => it?.batch_id?.id);
    const repeatArrState = repeats.some(
      (item: any, index: number) => repeats.indexOf(item) !== index
    );
    // if (repeatArrState) {
    //   dispatch(
    //     openAlert({
    //       message: "Cannot select a batch more than once.",
    //       severity: "warning",
    //     })
    //   );
    //   return;
    // }

    if (
      (validateMentor &&
        (inputFields[inputFields?.length - 1]?.program_id?.length === 0 ||
          inputFields[inputFields.length - 1]?.batch_id?.id?.length === 0)) ||
      alreadySelectedProgram?.length > 1
    ) {
      return;
    } else if (singleMentorDetail?.id) {
      const requiredObj: MentorRequiredObj = {
        url: "add-edit-mentor",
        body: {
          first_name: values.first_name,
          mentor_id: singleMentorDetail?.id,
          email: values.email,
          last_name: values.last_name,
          new_programs_data: onlyIds,
        },
      };

      setIsSubmitting(true);
      try {
        const resp: any = await addMentors(requiredObj).unwrap();
        if (resp.status) {
          dispatch(
            openAlert({
              message: resp.message,
              severity: "success",
            })
          );
          setUpdateList(!updateList);
          onHide();
          // setIsSubmitting(false);
        } else {
          dispatch(
            openAlert({
              message: resp.message,
              severity: "error",
            })
          );
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      const requiredObj: MentorRequiredObj = {
        url: "add-edit-mentor",
        body: {
          first_name: values.first_name,
          id: 0,
          email: values.email,
          last_name: values.last_name,
          new_programs_data: onlyIds,
        },
      };
      setIsSubmitting(true);
      try {
        const resp: any = await addMentors(requiredObj).unwrap();
        if (resp.status) {
          dispatch(
            openAlert({
              message: resp.message,
              severity: "success",
            })
          );
          setUpdateList(!updateList);
          onHide();
          setIsSubmitting(false);
        } else {
          dispatch(
            openAlert({
              message: resp.message,
              severity: "warning",
            })
          );
          setUpdateList(!updateList);
          onHide();
          setIsSubmitting(false);
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  const handleProgramSelected = (id: string) => {
    setProgramId(id);
  };

  const handleAlreadySelected = (id: string) => {
    const selectedItem = inputFields.filter(
      (item: any) => item.batch_id.id === id
    );
    const selectedItemProg = inputFields.filter(
      (item: any) => item.program_id === id
    );
    setAlreadySelectedProgram(selectedItemProg);
    if (inputFields.length === 1) {
      return;
    } else if (selectedItem.length > 0) {
      dispatch(
        openAlert({
          message: "This batch has already been selected!",
          severity: "warning",
        })
      );
    }
  };

  const disabledProgramIds = singleMentorDetail?.programs_with_batches?.map(
    (i: any, index: number) => {
      const disabledIdsIndex = inputFields?.findIndex(
        (it: any, ind: number) => it?.program_id === i?.program_id
      );
      return disabledIdsIndex;
    }
  );

  const disabledBatchIds = singleMentorDetail?.programs_with_batches?.map(
    (i: any, index: number) => {
      const disabledIdsIndex = inputFields?.findIndex(
        (it: any, ind: number) => it?.program_id === i?.batch_id
      );
      return disabledIdsIndex;
    }
  );

  const handleDisablePrograms = (index: number) => {
    if (
      disabledProgramIds?.some((it: number) => it === index) ||
      disabledBatchIds?.some((it: number) => it === index)
    ) {
      return true;
    }
    return false;
  };

  const handleAddMentorBtn = () => {
    setValidateMentor(true);
    if (alreadySelectedProgram.length > 1) {
      dispatch(
        openAlert({
          message: "This program already has been selected !",
          severity: "warning",
        })
      );
    }
  };

  const buttonText = () => {
    if (singleMentorDetail?.id && isLoading) {
      return "Saving...";
    } else if (singleMentorDetail?.id) {
      return "Save Changes";
    } else if (isSubmitting) {
      return "Adding Mentor...";
    } else {
      return "Add Mentor";
    }
  };

  const disableMentorEmail = () => {
    if (singleMentorDetail.email) {
      return true;
    } else return false;
  };

  const getBackColor = (item: any) => {
    if (item.difficulty === "intermediate") {
      return "var(--intermediateBackground)";
    } else if (item.difficulty === "pro") {
      return "var(--themeOrange)";
    } else {
      return "var(--topPerformer)";
    }
  };

  const disableAddIcon = () => {
    if (
      inputFields.length === 1 &&
      (!inputFields[0].program_id || !inputFields[0].batch_id?.id)
    ) {
      return true;
    } else if (
      inputFields[inputFields.length - 1]?.program_id === "" ||
      inputFields[inputFields.length - 1]?.batch_id?.id === "" ||
      inputFields[inputFields.length - 1]?.batch_id?.id === undefined ||
      alreadySelectedProgram?.length > 0
    )
      return true;
    else return false;
  };

  return (
    <>
      <Box sx={customStyle}>
        <Box
          component="div"
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Typography
            sx={{
              fontFamily: `var(--fontMulish)`,
              fontSize: "24px",
              fontStyle: "normal",
              fontWeight: "800",
              color: "var(-black)",
              marginBottom: "1.5rem",
              lineHeight: "normal",
            }}
          >
            {singleMentorDetail?.id ? "Edit" : "Add"} Mentor
          </Typography>
          <CloseIcon
            onClick={() => {
              onHide();
            }}
            fontSize="medium"
            sx={{
              color: "var(--emailTextForAssign)",
              cursor: "pointer",
            }}
          />
        </Box>
        <Formik
          initialValues={initialValues}
          validationSchema={validationMentorSchema}
          onSubmit={handleSubmitForm}
        >
          <Form>
            <Box
              display="flex"
              flexDirection="column"
              component="div"
              sx={{ my: 2 }}
            >
              <Box sx={{ marginBottom: "32px" }}>
                <FastField
                  style={{
                    border: "1px solid var(--borderInput)",
                    background: "var(--lightGray)",
                    fontWeight: "400",
                  }}
                  placeholder="Enter Mentor first name"
                  type="text"
                  emailAddress="Mentor first name"
                  name="first_name"
                  as={InputCustom}
                />
                <ErrorMessage name="first_name" component={ErrorText} />
              </Box>
              <Box sx={{ marginBottom: "32px" }}>
                <FastField
                  style={{
                    border: "1px solid var(--borderInput)",
                    background: "var(--lightGray)",
                    fontWeight: "400",
                  }}
                  placeholder="Enter Mentor last name"
                  type="text"
                  emailAddress="Mentor last name"
                  name="last_name"
                  as={InputCustom}
                />
                <ErrorMessage name="last_name" component={ErrorText} />
              </Box>
              <Box marginBottom="32px">
                <FastField
                  style={{
                    border: "1px solid var(--borderInput)",
                    background: "var(--lightGray)",
                    fontWeight: "400",
                    color: singleMentorDetail?.email
                      ? "var(--disableEmail)"
                      : "var(--black)",
                  }}
                  placeholder="Enter email address"
                  type="email"
                  emailAddress="Mentor email address"
                  name="email"
                  as={InputCustom}
                  disabled={disableMentorEmail()}
                />
                <ErrorMessage name="email" component={ErrorText} />
              </Box>

              <form onSubmit={handleSubmit}>
                <div className="form-row">
                  {inputFields.map((inputField: any, index: any) => {
                    return (
                      <>
                        <Box
                          display="flex"
                          flexDirection="column"
                          key={inputField}
                        >
                          <Box display="flex" flexDirection="column" gap="50px">
                            <FormControl
                              sx={{
                                marginBottom: "24px",
                                height: "40px",
                                width: "100%",
                              }}
                            >
                              <Typography sx={labelText}>Program Type</Typography>
                              <Select
                                IconComponent={ExpandMoreIcon}
                                sx={selectStyleBox}
                                displayEmpty
                                disabled={handleDisablePrograms(index)}
                                id="program_id"
                                name="program_id"
                                value={inputField.program_id}
                                onChange={(event) =>
                                  handleInputChange(index, event)
                                }
                                input={<OutlinedInput />}
                                renderValue={(selected) => {
                                  const filteredPrograms: any =
                                    techStackData?.data?.programs?.filter(
                                      (item: any) => item?.id === selected
                                    )[0];
                                  if (
                                    selected?.length === 0 ||
                                    filteredPrograms === undefined
                                  ) {
                                    return <Box>Select Program</Box>;
                                  }
                                  return filteredPrograms?.name;
                                }}
                                MenuProps={MenuProps}
                                inputProps={{ "aria-label": "Without label" }}
                              >
                                <MenuItem
                                  sx={{ display: "none" }}
                                  value=""
                                ></MenuItem>
                                {techStackData?.data?.programs?.map(
                                  (item: any, index: number) => {
                                    return (
                                      <MenuItem
                                        key={item.id}
                                        value={item.id}
                                        onClick={() =>
                                          handleProgramSelected(item.id)
                                        }
                                        sx={selectProgramChip}
                                      >
                                        <Box
                                          height="20px"
                                          width="max-content"
                                          marginTop="4px"
                                          padding="4px 10px"
                                          display="flex"
                                          justifyContent="center"
                                          alignItems="center"
                                          textTransform="capitalize"
                                          borderRadius="4px"
                                          fontSize="12px"
                                          fontFamily="var(--fontFamiljen)"
                                          fontStyle="normal"
                                          fontWeight="400"
                                          bgcolor={() => getBackColor(item)}
                                          border="1px solid var(--black)"
                                        >
                                          {item.difficulty}
                                        </Box>
                                        {item.name.length > 65 ? (
                                          <Tooltip
                                            title={
                                              item.name.length > 65
                                                ? item.name
                                                : null
                                            }
                                            defaultValue={item.id}
                                            arrow
                                            placement="top"
                                          >
                                            <Typography
                                              fontSize="14px"
                                              fontWeight="400"
                                              fontFamily="var(--fontFamiljen)"
                                              color="var(--activityText)"
                                            >
                                              {item.name.substring(0, 65) +
                                                "..."}
                                            </Typography>
                                          </Tooltip>
                                        ) : (
                                          <Typography
                                            fontSize="14px"
                                            fontWeight="400"
                                            fontFamily="var(--fontFamiljen)"
                                            color="var(--activityText)"
                                          >
                                            {item.name}
                                          </Typography>
                                        )}
                                      </MenuItem>
                                    );
                                  }
                                )}
                              </Select>
                              {validateMentor &&
                                inputField?.program_id?.length === 0 && (
                                  <Box
                                    marginTop="3px"
                                    className="errorHandleMessage"
                                  >
                                    Please select a program
                                  </Box>
                                )}
                            </FormControl>
                            <FormControl
                              sx={{
                                height: "40px",
                                width: "100%",
                                marginBottom: "32px",
                              }}
                            >
                              <Typography sx={labelText}>
                                Assign Batch
                              </Typography>
                              <Select
                                IconComponent={ExpandMoreIcon}
                                sx={{
                                  ...selectStyleBox,
                                  cursor:
                                    inputFields[index].program_id === ""
                                      ? "not-allowed"
                                      : "pointer",
                                }}
                                displayEmpty
                                id="batch_id"
                                name="batch_id"
                                disabled={
                                  singleMentorDetail?.id
                                    ? handleDisablePrograms(index)
                                    : inputFields[index].program_id === ""
                                }
                                value={inputField.batch_id}
                                onChange={(event) =>
                                  handleInputChange(index, event)
                                }
                                input={<OutlinedInput />}
                                renderValue={(selected) => {
                                  if (selected?.id?.length === 0) {
                                    return <Box>Select Batch</Box>;
                                  }
                                  return selected?.name;
                                }}
                                MenuProps={MenuProps}
                                inputProps={{ "aria-label": "Without label" }}
                              >
                                <MenuItem
                                  sx={{ display: "none" }}
                                  value=""
                                ></MenuItem>
                                {batchIsLoading || batchIsFetching ? (
                                  <MenuItem disabled={true}>
                                    Loading batches
                                  </MenuItem>
                                ) : !allBatches?.data?.length ? (
                                  <MenuItem disabled={true}>
                                    No batches found!
                                  </MenuItem>
                                ) : (
                                  allBatches?.data?.map(
                                    (item: any, index: number) => (
                                      <MenuItem
                                        onClick={() =>
                                          handleAlreadySelected(item._id)
                                        }
                                        key={item?._id}
                                        value={item}
                                        style={getStyles(item.name, theme)}
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          alignItems: "baseline",
                                          fontWeight: 500,
                                          height: "56px",
                                          fontSize: "14px",
                                          fontFamily: "var(--fontFamiljen)",
                                          color: "var(--activityText)",
                                        }}
                                      >
                                        <Typography
                                          fontSize="14px"
                                          fontWeight="400"
                                          fontFamily="var(--fontFamiljen)"
                                          color="var(--activityText)"
                                        >
                                          {item?.name}
                                        </Typography>
                                      </MenuItem>
                                    )
                                  )
                                )}
                              </Select>
                              {validateMentor &&
                                inputField?.batch_id?.id?.length === 0 && (
                                  <Box
                                    marginTop="3px"
                                    className="errorHandleMessage"
                                  >
                                    Batch is required
                                  </Box>
                                )}
                            </FormControl>
                          </Box>
                          <Box
                            margin="20px 0"
                            width="100%"
                            display="flex"
                            justifyContent="flex-end"
                            flexDirection="column"
                            alignItems="end"
                            className="form-group col-sm-2"
                          >
                            {inputField?.temp &&
                              <Button>
                                <Typography
                                  fontSize="12px"
                                  fontWeight="400"
                                  fontFamily="var(--fontFamiljen)"
                                  fontStyle="normal"
                                  color="var(--themeOrange)"
                                  lineHeight="15px"
                                  display={
                                    inputFields.length === 1 ||
                                      handleDisablePrograms(index)
                                      ? "none"
                                      : "flex"
                                  }
                                  onClick={() => handleRemoveFields(index)}
                                >
                                  Delete
                                </Typography>
                              </Button>
                            }
                          </Box>
                        </Box>
                      </>
                    );
                  })}
                </div>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                  gap="5px"
                  marginTop="-20px"
                  marginBottom="40px"
                >
                  <Box
                    sx={{
                      cursor:
                        inputFields[inputFields.length - 1]?.program_id ===
                          "" || alreadySelectedProgram?.length > 0
                          ? "not-allowed"
                          : "pointer",
                    }}
                  >
                    <Button
                      onClick={() => handleAddFields()}
                      // disabled={disableAddIcon()}
                    >
                      <AddIcon
                        sx={{ color: "var(--themeOrange)" }}
                        fontSize="small"
                      />
                      <Typography
                        fontSize="12px"
                        textTransform="capitalize"
                        fontWeight="400"
                        fontFamily="var(--fontFamiljen)"
                        fontStyle="normal"
                        color="var(--themeOrange)"
                        lineHeight="15px"
                      >
                        Add Program
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              </form>

              <Box
                component="div"
                gap="10px"
                marginTop="0px"
                display="flex"
                justifyContent="center"
                alignItems="end"
                sx={{
                  marginBottom: "144px",
                  width: "100%",
                }}
              >
                <Buttons
                  type="button"
                  onClick={() => onHide()}
                  name="Cancel"
                  variant="contained"
                  size="large"
                  fullWidth={true}
                  sx={{
                    padding: "0.8rem",
                    borderRadius: "8px",
                    textTransform: "capitalize",
                    height: "56px",
                    backgroundColor: "var(--borderInput)",
                    boxShadow: "none",
                    color: "var(--legendColor)",
                  }}
                  className="BtnHoverDisable"
                />
                <Buttons
                  type="submit"
                  name={buttonText()}
                  disabled={isSubmitting ? true : false}
                  variant="contained"
                  onClick={() => handleAddMentorBtn()}
                  color="primary"
                  size="large"
                  fullWidth={true}
                  sx={{
                    padding: "0.8rem",
                    borderRadius: "8px",
                    height: "56px",
                    fontWeight: "400",
                    fontSize: "16px",
                    textTransform: "capitalize",
                  }}
                  className="BtnHover"
                />
              </Box>
            </Box>
          </Form>
        </Formik>
      </Box>
    </>
  );
};

export default MentorDrawer;
