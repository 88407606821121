import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Box, CssBaseline, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import Header from './Header'
import useUser from '../hooks/useUser'
import OnboardService from '../Pages/Onboarding/onboaring.service'
import { USER_TYPE } from '../constants'
import { Gray, Orange, Shades } from '../Style/colors'
import { getEpoch } from '../utils/formatDate'
import { storeAuthToken, storeLoginToken } from '../redux/slices/authSlice'
import {
  LearnerDashboardIcon,
  LearnerDashboardIconActive,
  LearnerLms,
  LearnerLmsActive,
  LearnerProgressTracking,
  LearnerProgressTrackingActive,
  ScheduleIcon,
  ScheduleIconActive,
  LearnerBook,
  LearnerLmsActiveBook,
} from '../assets/logos/logos'

const Layout = (props: any) => {
  const upcomingClassRef = useRef(null);
  const [open, setOpen] = useState(true)
  const refreshToken = useSelector((state: any) => state?.authSlice?.refreshToken)
  const dispatch = useDispatch()
  const user = useUser()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const triggerScroll: any = useSelector(
    (state: any) => state?.mainSidebarSlice?.scroll
  );

  const isMentor = user?.user_type === USER_TYPE.MENTOR

  function onNavigateToDashboard() {
    navigate(isMentor ? '/dashboard' : '/my_dashboard')
  }

  function onNavigateToPrograms() {
    navigate('/programs')
  }

  function onNavigateToLearners() {
    navigate('/learner')
  }

  function onNavigateToProgressTracking() {
    navigate('/progress')
  }

  function onNavigateToSchedules() {
    navigate(isMentor ? '/schedules' : '/learner_schedules')
  }

  useEffect(() => {
    const timer = setInterval(() => {
      ; (async () => {
        const res = await OnboardService.renewToken(refreshToken)
        if (res?.status) {
          dispatch(storeLoginToken(res?.data?.token))
          dispatch(storeAuthToken({ ...res?.data, iua: getEpoch() }))
        }
      })()
    }, 3540000) // 59 min

    return () => clearInterval(timer)
  })

  let scheduleIconSource = ScheduleIcon
  if (pathname.includes('/schedules') || pathname.includes('/learner_schedules')) {
    scheduleIconSource = ScheduleIconActive
  }

  const handleSideBarMenuIconsChange = () => {
    let dashboardIconSrc =
      pathname.includes('/dashboard') || pathname.includes('/my_dashboard')
        ? LearnerDashboardIconActive
        : LearnerDashboardIcon

    return <img src={dashboardIconSrc} alt='LearnerDashboardIcon' />
  }

  // hide side menu when user is on task details page
  // const hideLayout = pathname.startsWith(`/programs/`) || pathname.startsWith("/learner/");
  const hideLayout = /^\/programs\/[a-zA-Z0-9]+\/[a-zA-Z0-9]+$/.test(pathname) || /^\/learner\/[a-zA-Z0-9]+\/[a-zA-Z0-9]+$/.test(pathname)

  useEffect(() => {
    // @ts-ignore
    upcomingClassRef?.current?.scrollTo(0, 0);
  }, [triggerScroll])

  useEffect(() => {
    // collapse view of side menu when user is on module listing page
    const isProgramIdOnly = /^\/programs\/[a-zA-Z0-9]+$/.test(pathname) || /^\/learner\/[a-zA-Z0-9]+$/.test(pathname)
    if (pathname.startsWith('/programs/') || pathname.startsWith("/learner/")) {
      if (isProgramIdOnly) {
        setOpen(false);
      }
    } else {
      setOpen(true);
    }

  }, [pathname])

  return (
    <>
      <Header isLoggedIn />
      <CssBaseline />

      <Box sx={{ width: '100%', height: 'calc(100vh - 62px)', display: 'flex', flexDirection: 'row' }}>
        {/* Drawer */}
        {hideLayout ? null :
          <Box sx={{ minWidth: 80, maxWidth: 256, background: Shades['101'] }} style={{ width: open ? '100%' : '0%' }}>
            <List sx={{ width: '100%', maxWidth: 256 }} component='nav' aria-labelledby='nested-list-subheader'>
              {/* <ListItemButton sx={{ p: '21px 28px' }} onClick={() => setOpen(!open)}>
                <ListItemIcon sx={{ color: "white" }} >{open ? "<" : ">"}</ListItemIcon>
              </ListItemButton> */}
              <ListItemButton sx={{ p: '21px 28px' }} onClick={onNavigateToDashboard}>
                <ListItemIcon>{handleSideBarMenuIconsChange()}</ListItemIcon>

                {open && (
                  <ListItemText
                    primary='Dashboard'
                    sx={{
                      color:
                        pathname.includes('/my_dashboard') || pathname.includes('/dashboard')
                          ? Orange['600']
                          : Shades['0'],
                      fontFamily: 'var(--fontFamiljen)',
                    }}
                  />
                )}
              </ListItemButton>
              {!isMentor && (
                <ListItemButton sx={{ p: '21px 28px' }} onClick={onNavigateToPrograms}>
                  <ListItemIcon>
                    {pathname.includes('/programs') ? (
                      <img src={LearnerLmsActive} alt='LearnerLmsActive' />
                    ) : (
                      <img src={LearnerLms} alt='LearnerLms' />
                    )}
                  </ListItemIcon>
                  {open && (
                    <ListItemText
                      primary='Programs'
                      sx={{
                        color: pathname.includes('/programs') ? Orange['600'] : Shades['0'],
                        fontFamily: 'var(--fontFamiljen)',
                      }}
                    />
                  )}
                </ListItemButton>
              )}
              {isMentor && (
                <ListItemButton sx={{ p: '21px 28px' }} onClick={onNavigateToLearners}>
                  <ListItemIcon>
                    {/* <MenuBookIcon sx={iconColor} /> */}
                    {pathname.includes('/learner') ? (
                      <img src={LearnerLmsActiveBook} alt='learner' width={24} height={24} />
                    ) : (
                      <img src={LearnerBook} alt='LearnerLms' width={24} height={24} />
                    )}
                  </ListItemIcon>
                  {open && (
                    <ListItemText
                      primary='Learners'
                      sx={{
                        color: pathname.includes('/learner') ? Orange['600'] : Shades['0'],
                        fontFamily: 'var(--fontFamiljen)',
                      }}
                    />
                  )}
                </ListItemButton>
              )}

              <ListItemButton sx={{ p: '21px 28px' }} onClick={onNavigateToSchedules}>
                {/* <ListItemIcon>
                {pathname.includes("/schedules") ? (
                  <img src={ScheduleIconActive} alt="ScheduleIconActive" />
                ) : pathname.includes("/learner_schedules") ? (
                  <img src={ScheduleIconActive} alt="ScheduleIconActive" />
                ) : (
                  <img src={ScheduleIcon} alt="ScheduleIcon" />
                )}
              </ListItemIcon> */}

                <ListItemIcon>
                  <img src={scheduleIconSource} alt='ScheduleIcon' />
                </ListItemIcon>
                {open && (
                  <ListItemText
                    primary='Schedules'
                    sx={{
                      color:
                        pathname.includes('/learner_schedules') || pathname.includes('/schedules')
                          ? Orange['600']
                          : Shades['0'],
                      fontFamily: 'var(--fontFamiljen)',
                    }}
                  />
                )}
              </ListItemButton>

              {!isMentor && (
                <ListItemButton sx={{ p: '21px 28px' }} onClick={onNavigateToProgressTracking}>
                  <ListItemIcon>
                    {pathname.includes('/progress') ? (
                      <img src={LearnerProgressTrackingActive} alt='LearnerProgressTrackingActive' />
                    ) : (
                      <img src={LearnerProgressTracking} alt='LearnerProgressTracking' />
                    )}
                  </ListItemIcon>
                  {open && (
                    <ListItemText
                      primary='Progress'
                      sx={{
                        color: pathname.includes('/progress') ? Orange['600'] : Shades['0'],
                        fontFamily: 'var(--fontFamiljen)',
                      }}
                    />
                  )}
                </ListItemButton>
              )}
            </List>
          </Box>}

        {/* Main Container */}
        <Box
          ref={upcomingClassRef}
          sx={{
            p: '0 24px 0 16px',
            width: '100%',
            height: '100%',
            overflow: 'auto',
            backgroundColor: hideLayout ? Gray["50"] : "",
          }}
        >
          {props?.children}
        </Box>
      </Box>
    </>
  )
}

export default Layout
