import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { format, parseISO } from "date-fns";
import LocalizedFormat from "dayjs/plugin/localizedFormat";

dayjs.extend(utc);
dayjs.extend(LocalizedFormat);

export function formatDateFromNow(time: any) {
  return `${dayjs().diff(dayjs(time), "day")}d ${dayjs().diff(dayjs(time), "hour")}h`;
}

export function formatTimeToLocal(time: any) {
  return `${dayjs(time).format("MMM DD YYYY - LT")}`;
}

export function getEpoch() {
  return dayjs().unix();
}

export function formatDate(date: string) {
  if (!date) {
    return "";
  }
  const dateObject = parseISO(date);
  const formattedDate = format(dateObject, "dd MMM yyyy");
  return formattedDate;
}

export function extractTime(dateTimeString: string) {
  const date = new Date(dateTimeString);

  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";

  hours = hours % 12;
  hours = hours ? hours : 12;
  const formattedHours = hours.toString().padStart(2, "0");

  const time = `${formattedHours}:${minutes} ${ampm}`;

  return time ?? "";
}

export function convertSeconds(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const timeParts = [];

  if (hours > 0) timeParts.push(`${hours} hours`);
  if (minutes > 0) timeParts.push(`${minutes} minutes`);
  if (remainingSeconds > 0) timeParts.push(`${remainingSeconds} seconds`);

  return timeParts.join(", ");

}
