import { createApi } from "@reduxjs/toolkit/query/react";
import { apiRoot, baseQuery, websiteApiRoot } from "../../global";

export const api = createApi({
  reducerPath: "api",
  baseQuery: baseQuery,
  tagTypes: [
    "Programs",
    "Batch",
    "Publish",
    "Modules",
    "Tasks",
    "UserManagement",
    "Learner",
    "Mentors",
    "Feedback",
    "Progress",
    "Plan",
    "Login",
    "SuggestedPrograms",
    "ImageUpload",
    "Dashboard",
    "openAI",
    "Account",
    "RolesAndPermission",
  ],
  endpoints: (builder) => ({
    tappLogin: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
    }),

    getAllSuggestedPrograms: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}suggested-program-list?offset=${data.offset || 0}&limit=${
          data.limit
        }&programName=${data.programName}`,
        method: "GET",
      }),
      providesTags: ["SuggestedPrograms"],
    }),

    getSuggestedProgramDetail: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "GET",
      }),
      providesTags: ["SuggestedPrograms"],
    }),

    addProgramsToAllPrograms: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["SuggestedPrograms"],
    }),

    getProgramsList: builder.query<any, any>({
      query: (data: any) => ({
        url: data.programName
          ? `${apiRoot}program-list?offset=${data.offset || 0}&limit=${
              data.limit || 100
            }&is_published=${data.isPublished}&programName=${data.programName}`
          : `${apiRoot}program-list?offset=${data.offset || 0}&limit=${
              data.limit || 100
            }&is_published=${data.isPublished}`,
      }),
      providesTags: ["Programs", "Publish", "UserManagement"],
    }),

    getPrerequisiteProgram: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}pre-requisite-programs-list`,
        method: "GET",
        body: data.body,
      }),
    }),

    addProgramsLMS: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["Programs"],
    }),

    deleteProgramme: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "DELETE",
        body: data.body,
      }),
      invalidatesTags: ["Programs"],
    }),

    addPublishPrograms: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["Publish"],
    }),
    // demo
    getAllModulesList: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}module-list?offset=${data.offset || 0}&limit=${
          data.limit || 100
        }&program_id=${data.program_id}&module_name=${data.module_name}`,
        method: "GET",
      }),
      providesTags: ["Modules"],
    }),

    addModules: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["Modules"],
    }),

    deleteModules: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "DELETE",
        body: data.body,
      }),
      invalidatesTags: ["Modules"],
    }),

    getAllTasks: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}?module_id=${data.id}`,
        method: "GET",
      }),
      providesTags: ["Tasks"],
    }),

    addTasks: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["Tasks"],
    }),

    editTasks: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["Tasks"],
    }),

    detailsTask: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}?task_id=${data.id}`,
        method: "GET",
      }),
      providesTags: ["Tasks"],
    }),

    deleteTask: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}?task_id=${data.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Tasks"],
    }),

    getAllfeedbackParamsList: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}feedback-param?offset=${data.offset}&limit=${data.limit}&name=${data.name}`,
        method: "GET",
      }),
      providesTags: ["Feedback"],
    }),

    addFeedback: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["Feedback"],
    }),

    deleteFeedbackParameter: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}?id=${data.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Feedback"],
    }),

    addLearner: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["Learner"],
    }),

    editLearner: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["Learner"],
    }),

    deleteLearner: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}?org_id=${data.orgId}&learner_id=${data.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Learner"],
    }),

    allMentorByStackList: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}?org_id=${data.org_id}&tech_stack=${data.tech_id}`,
        method: "GET",
      }),
      providesTags: ["Learner"],
    }),

    getMentorByStackList: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}?org_id=${data.org_id}&tech_stack=${data.tech_id}`,
        method: "GET",
      }),
      invalidatesTags: ["Learner"],
    }),

    getMentorList: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}?org_id=${data.id}`,
        method: "GET",
      }),
      providesTags: ["Mentors"],
    }),

    addMentor: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["Mentors"],
    }),

    editMentor: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["Mentors"],
    }),

    deleteMentor: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}?mentor_id=${data.id}&org_id=${data.orgId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Mentors"],
    }),

    getProgressListData: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}progress-list?offset=${data.offset || 0}&limit=${
          data.limit || 100
        }&tech_id=${data.tech_id}&filter_by=${data.filter_by}&name=${data.name}`,
        method: "GET",
      }),
      providesTags: ["Progress"],
    }),

    getProgressLearnerDetails: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}progress?program_id=${data.program_id}&user_id=${data.user_id}`,
        method: "GET",
      }),
      providesTags: ["Progress"],
    }),

    getPlan: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "GET",
      }),
      providesTags: ["Plan"],
    }),

    getLearnersList: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}?offset=${data.offset || 0}&limit=${data.limit || 100}&org_id=${
          data.org_id
        }&tech_ids=${data.tech_ids || ""}&learner_name=${data.learner_name || ""}`,
        method: "GET",
      }),
      providesTags: ["UserManagement"],
    }),

    getMentorsList: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}?offset=${data.offset || 0}&limit=${data.limit || 1000}&org_id=${
          data.org_id
        }&tech_ids=${data.tech_ids || ""}&mentor_name=${data.mentor_name || ""}`,
        method: "GET",
      }),
      providesTags: ["UserManagement"],
    }),

    getRolesAssigneesList: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}?offset=${data.offset || 0}&limit=${
          data.limit || 100
        }&role_ids=${data.role_ids || ""}&searchText=${data.searchText || ""}`,
        method: "GET",
      }),
      providesTags: ["UserManagement"],
    }),

    getAllReassignMentors: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["UserManagement"],
    }),

    addLearners: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["UserManagement"],
    }),

    deleteLearners: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "DELETE",
        body: data.body,
      }),
      invalidatesTags: ["UserManagement"],
    }),

    deleteMentors: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "DELETE",
        body: data.body,
      }),
      invalidatesTags: ["UserManagement"],
    }),

    addMentors: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["UserManagement"],
    }),

    getSingleLearnerDetail: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}?userId=${data.userId}`,
        method: "GET",
      }),
      providesTags: ["UserManagement"],
    }),

    otpLogin: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["Login"],
    }),

    otpVerify: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["Login"],
    }),

    uploadFileToGetSignedURL: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["ImageUpload"],
    }),

    uploadFileToS3Bucket: builder.mutation({
      query: ({ signedURL, file }) => ({
        url: signedURL,
        method: "PUT",
        headers: { "Content-Type": file.type },
        body: file,
      }),
      invalidatesTags: ["ImageUpload"],
    }),

    deleteTaskImage: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["ImageUpload"],
    }),

    getAllDashboardAnlytics: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}dashboard-analytics`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["Dashboard"],
    }),
    createPorogramWithAI: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["openAI"],
    }),
    getAccountProfileInfo: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "GET",
      }),
      invalidatesTags: ["Account"],
    }),
    getAllRolesAndPermissionList: builder.query<any, any>({
      query: () => ({
        url: `${apiRoot}roles-list`,
        method: "GET",
      }),
      providesTags: ["RolesAndPermission"],
    }),
    getAllRolesAssigneeList: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}?role_id=${data.roleId}`,
        method: "GET",
      }),
      invalidatesTags: ["RolesAndPermission"],
    }),
    assignRoles: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["RolesAndPermission", "UserManagement"],
    }),
    removeAssignee: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}?assinee_id=${data.roleId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["RolesAndPermission"],
    }),
    createRoleDetails: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "GET",
      }),
      providesTags: ["RolesAndPermission"],
    }),
    addRoleAndPermissions: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["RolesAndPermission"],
    }),
    getCheckedRoleDetails: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}/${data.roleId}`,
        method: "GET",
      }),
      invalidatesTags: ["RolesAndPermission"],
    }),
    deleteRoleAndPermissions: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}/${data.roleId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["RolesAndPermission"],
    }),
    getAllDashboardDetails: builder.query<any, any>({
      query: (data: any) => ({
        url:
          data.startDate && data.endDate
            ? `${apiRoot}dashboard-program-performance?program_id=${data.id}&time_period=${data.date}&start_date=${data.startDate}&end_date=${data.endDate}`
            : `${apiRoot}dashboard-program-performance?program_id=${data.id}&time_period=${data.date}`,
        method: "GET",
      }),
      providesTags: ["Dashboard"],
    }),
    getCompanyDashboardDetails: builder.query<any, any>({
      query: (data: any) => ({
        url:
          data.startDate && data.endDate
            ? `${apiRoot}${data.url}?time_period=${data.date}&start_date=${data.startDate}&end_date=${data.endDate}`
            : `${apiRoot}${data.url}?time_period=${data.date}`,
        method: "GET",
      }),
      providesTags: ["Dashboard"],
    }),

    addBatch: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "POST",
        body: data.body,
      }),
      invalidatesTags: ["Batch"],
    }),

    getSingleBatch: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}/${data.batchId}`,
        method: "GET",
      }),
      providesTags: ["Batch"],
    }),

    getAllBatches: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}?program_id=${data.program_id}&mentor_assigned=${data.mentor_assigned}`,
        method: "GET",
      }),
      providesTags: ["Batch"],
    }),

    getAllLearnerBatches: builder.query<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}?program_id=${data.program_id}&mentor_id=${data.mentor_id}`,
        method: "GET",
      }),
      providesTags: ["Batch"],
    }),

    updateBatch: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}/${data.batchId}`,
        method: "PATCH",
        body: data.body,
      }),
      invalidatesTags: ["Batch"],
    }),

    deleteSingleBatch: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}/${data.batchId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Batch"],
    }),

    deleteMultipleBatches: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}${data.url}`,
        method: "DELETE",
        body: data.body,
      }),
      invalidatesTags: ["Batch"],
    }),

    sortModule: builder.mutation<any, any>({
      query: (data: any) => ({
        url: `${apiRoot}sort-module`,
        method: "POST",
        body: data,
      }),
    }),

    getContact: builder.query<any, any>({
      query: (data: any) => ({
        url: `${websiteApiRoot}lead/list?page=${data.page}&limit=${data.limit}&ctas=${data.cta}&name=${data.name}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useTappLoginMutation,
  useAddProgramsLMSMutation,
  useDeleteProgrammeMutation,
  useAddModulesMutation,
  useDeleteModulesMutation,
  useGetAllTasksQuery,
  useAddTasksMutation,
  useEditTasksMutation,
  useDeleteTaskMutation,
  useGetAllfeedbackParamsListQuery,
  useAddFeedbackMutation,
  useDeleteFeedbackParameterMutation,
  useDeleteLearnerMutation,
  useAddLearnerMutation,
  useEditLearnerMutation,
  useGetMentorByStackListMutation,
  useLazyAllMentorByStackListQuery,
  useGetMentorListQuery,
  useAddMentorMutation,
  useEditMentorMutation,
  useDeleteMentorMutation,
  useDeleteTaskImageMutation,
  useGetPlanQuery,
  useOtpLoginMutation,
  useOtpVerifyMutation,
  useUploadFileToGetSignedURLMutation,
  useUploadFileToS3BucketMutation,
  useDetailsTaskQuery,
  useAddLearnersMutation,
  useAddMentorsMutation,
  useDeleteMentorsMutation,
  useDeleteLearnersMutation,
  useGetAllDashboardAnlyticsMutation,
  useCreatePorogramWithAIMutation,
  useGetAccountProfileInfoMutation,
  useGetAllRolesAndPermissionListQuery,
  useGetAllRolesAssigneeListMutation,
  useAssignRolesMutation,
  useRemoveAssigneeMutation,
  useCreateRoleDetailsQuery,
  useAddRoleAndPermissionsMutation,
  useGetCheckedRoleDetailsMutation,
  useDeleteRoleAndPermissionsMutation,
  useGetAllReassignMentorsMutation,
  useGetSuggestedProgramDetailQuery,
  useAddProgramsToAllProgramsMutation,
  useAddPublishProgramsMutation,
  useGetAllDashboardDetailsQuery,
  useGetCompanyDashboardDetailsQuery,
  useGetProgramsListQuery,
  useGetAllModulesListQuery,
  useGetLearnersListQuery,
  useGetMentorsListQuery,
  useGetRolesAssigneesListQuery,
  useGetSingleLearnerDetailQuery,
  useGetAllSuggestedProgramsQuery,
  useGetProgressListDataQuery,
  useGetProgressLearnerDetailsQuery,
  useAddBatchMutation,
  useGetSingleBatchQuery,
  useGetAllBatchesQuery,
  useUpdateBatchMutation,
  useGetAllLearnerBatchesQuery,
  useDeleteSingleBatchMutation,
  useDeleteMultipleBatchesMutation,
  useGetPrerequisiteProgramQuery,
  useGetContactQuery,
  useSortModuleMutation,
} = api;
