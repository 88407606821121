import { Fragment, useMemo, useState } from "react";
import {
  Typography,
  Box,
  IconButton,
  Button,
  Tooltip,
  Pagination,
} from "@mui/material";
import { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import {
  useDeleteLearnersMutation,
  useDeleteMentorsMutation,
  useGetLearnersListQuery,
  useGetMentorsListQuery,
  useGetProgramsListQuery,
  useGetRolesAssigneesListQuery,
  useGetSingleLearnerDetailQuery,
  useRemoveAssigneeMutation,
} from "../../redux/api/api";
import BreadCrumbs from "../../components/reusables/BreadCrumbs";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { StyledSearchInput } from "../../Style/style";
import NoModulesFound from "../LMS/modules/NoModulesFound";
import DataTable from "react-data-table-component";
import SkeletonTable from "../../components/reusables/SkeletonTable";
import LearnerDrawer from "./learner/LearnerDrawer";
import UserManagementDrawer from "./UserManagementDrawer";
import MentorDrawer from "./mentor/MentorDrawer";
import { useDispatch, useSelector } from "react-redux";
import { deleteIcon } from "../../assets/logos/logos";
import { openAlert } from "../../redux/slices/snackbarSlice";
import AlertBox from "../../components/reusables/AlertBox";
import LearnerDetailDrawer from "./learner/LearnerDetailDrawer";
import DeleteMentorDrawer from "./mentor/DeleteMentorDrawer";
import OtherMember from "./otherMember/OtherMember";
import {
  LearnerItemsInterface,
  MentorItemsInterface,
  OtherMembersItemsInterface,
  AllowedPermissionsInterface,
} from "../../Interface/Interface";
import useDebounce from "../../components/utils/useDebounce";
import FilterBox from "./FilterBox";
import { styled } from "@mui/styles";
import Delete from "../../assets/icons/delete-program.svg";
import NoLearnerFound from "../../assets/icons/no-learnerFound.svg";
import NoMentorFound from "../../assets/icons/no-mentorFound.svg";
import {
  chunkSizeMentor,
  chunkSizeMentorTooltip,
  chunkSizeProgressTracking,
} from "../../components/utils/constants";
import { splitStringIntoChunks } from "../../components/utils/splitStringIntoChunks";
import debounce from "../../utils/debounce";

const mentorDeleteText = "Are you sure you want to delete this mentor?";
const learnerDeleteText = "Are you sure you want to delete this learner?";
const learnerMultipleDeleteText =
  "Are you sure you want to delete these learners?";
const memberDeleteText = "Are you sure you want to delete this member?";

const toggelBtnClass = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  textTransform: "capitalize",
  alignItems: "center",
  border: "none",
  padding: "7px 10px",
  gap: "10px",
  backgroundColor: "var(--lightGray)",
  textWrap: "nowrap",
  "&:focus": {
    backgroundColor: "var(--themeOrange) !important",
    color: "#fff !important",
    fontFamily: "var(--fontFamiljen)",
  },
  height: "36px",
  color: "var(--inerTextColor)",
  fontFamily: "var(--fontFamiljen)",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "20px",
};

const Learner = "Learner"
const Mentor = "Mentor"
const Other_Member = "Other Member"


const limit = 10;
const limitMentor = 10;
const limitOtherMember = 10;

export const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "var(--black)",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "var(--black)",
    fontSize: "12px",
    fontFamily: "var(--fontFamiljen)",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "18px",
  },
}));

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }: any) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff !important",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: 11,
  },
}));

const UserManagement = () => {
  const dispatch = useDispatch();
  const [openLearnerDrawer, setOpenLearnerDrawer] = useState<boolean>(false);
  const [learnerDetailDrawer, setLearnerDetailDrawer] =
    useState<boolean>(false);
  const [confirmBox, setConfirmBox] = useState<boolean>(false);
  const [visibleId, setVisibleId] = useState<any>("");
  const [itemClickedId, setItemClickedId] = useState<any>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [offset, setOffset] = useState<number>(0);
  const [currentPageMentor, setCurrentPageMentor] = useState<number>(1);
  const [offsetMentor, setOffsetMentor] = useState<number>(0);
  const [offsetOtherMember, setOffsetOtherMember] = useState<any>(0);
  const [currentPageOtherMember, setCurrentPageOtherMember] = useState<number>(1);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [management, setManagement] = useState<any>("");
  const [alignment, setAlignment] = useState<string>(Learner);
  const [updateList, setUpdateList] = useState<boolean>(false);
  const [programIdArray, setProgramIdArray] = useState<any>([]);
  const [mentorIdArray, setMentorIdArray] = useState<any>([]);
  const [membersIdArray, setMembersIdArray] = useState<any>([]);
  const [deleteLearnerIdArray, setDeleteLearnerIdArray] = useState<any>([]);
  const [singleMentorDetail, setSingleMentorDetail] = useState<any>("");
  const [deleteMentorId, setDeleteMentorId] = useState<any>({
    id: "",
    name: "",
  });
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [otherMembersDeleteId, setOtherMembersDeleteId] = useState<string>("");
  const [filteredSingleMember, setFilteredSingleMember] = useState<any>({});

  // const debouncedValue: any = useDebounce(management, 500);

  const changeSearchValueWithOffset = (event: any) => {
    setOffset(0)
    setOffsetMentor(0);
    setOffsetOtherMember(0);
    setCurrentPage(1);
    setCurrentPageMentor(1);
    setCurrentPageOtherMember(1);
    setManagement(event?.target?.value);
  }

  const organizationId: any = useSelector(
    (state: any) => state?.authSlice?.userToken
  );

  const isMainCompanyAdmin = useSelector(
    (state: any) => state?.authSlice?.isMainCompanyAdmin
  );

  const allowedPermissions = useSelector(
    (state: any) => state?.authSlice?.permissions
  );

  const { data: programsList, refetch } = useGetProgramsListQuery({
    isPublished: true,
    refetchOnMountOrArgChange: true,
  });

  const reqObject = {
    url: "learner-list",
    limit,
    offset,
    org_id: organizationId,
    tech_ids: programIdArray.toString(),
    learner_name: management,
  };

  const {
    data,
    isLoading: learnersLoading,
    isFetching: learnerFetch,
    refetch: learnerRefetch,
  } = useGetLearnersListQuery(reqObject);

  const reqObjectMentors = {
    url: "mentor-list",
    limit: limitMentor,
    offset: offsetMentor,
    org_id: organizationId,
    tech_ids: mentorIdArray.toString(),
    mentor_name: management,
  };

  const {
    data: mentorData,
    isLoading: mentorsLoading,
    isFetching: mentorFetch,
  } = useGetMentorsListQuery(reqObjectMentors);

  const reqMember = {
    url: "assignee-list",
    offset: offsetOtherMember,
    limit: limitOtherMember,
    role_ids: membersIdArray.toString(),
    searchText: management,
  };

  const {
    data: otherMembersData,
    isLoading: otherMembersLoading,
    isFetching: otherFetch,
  } = useGetRolesAssigneesListQuery(reqMember);

  const reqObjLearnerDetail = {
    url: "learner-detail",
    userId: itemClickedId,
  };
  const { data: singleLearnerDetail, isFetching: singleLearnerLoading } =
    useGetSingleLearnerDetailQuery(reqObjLearnerDetail, {
      skip: !itemClickedId,
    });

  const [deleteLearners, { isLoading: deleteLearnerLoading }] =
    useDeleteLearnersMutation();
  const [removeAssignee] = useRemoveAssigneeMutation();
  const [deleteMentors] = useDeleteMentorsMutation();

  const filteredPermission = allowedPermissions?.filter(
    (item: AllowedPermissionsInterface) => item?.name === "User Management"
  )[0];

  const handleEditMentor = (id: any) => {
    const filterData: any = mentorData?.data?.mentors?.filter(
      (item: any) => item.id === id
    )[0];
    if (!filterData) return;
    setSingleMentorDetail(filterData);
    setOpenLearnerDrawer(true);
  };

  const handleEditOtherMember = (id: any) => {
    const filterData: any = otherMembersData?.data?.assigneeList?.filter(
      (item: any) => item.id === id
    )[0];
    if (!filterData) return;
    setFilteredSingleMember(filterData);
    setOpenLearnerDrawer(true);
  };

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    if (newAlignment !== Learner) {
      setShowDelete(false);
    }
    if (newAlignment === null) {
      setAlignment(Learner);
      // setManagement("");
    } else {
      // setManagement("");
      setAlignment(newAlignment);
    }
    setProgramIdArray([]);
    setMentorIdArray([]);
    setMembersIdArray([]);
  };

  const emailAddressLearner = (items: LearnerItemsInterface) => {
    return (
      <EmailTypography>
        {items?.email?.length > 20
          ? items?.email?.slice(0, 20) + "..."
          : items?.email}
      </EmailTypography>
    );
  };

  const assignedProgramsLearner = (items: LearnerItemsInterface) => {
    return (
      <Box
        onClick={() => handleRowClicked(items?.id)}
        display="flex"
        alignItems="center"
        gap="5px"
      >
        {items?.programs?.length > 1 ? (
          <Typography
            color="var(--black)"
            fontFamily="var(--fontFamiljen)"
            fontStyle="normal"
            fontWeight="400"
            fontSize="14px"
            lineHeight="18px"
          >
            {items?.programs?.length !== 1 &&
              items?.programs[0]?.program_name?.length > 15
              ? items?.programs[0]?.program_name.substring(0, 15) + "..."
              : items?.programs[0]?.program_name}
          </Typography>
        ) : (
          <LightTooltip
            title={
              <Box
                boxShadow="2px 2px 5px grey"
                borderRadius="8px"
                width="220px"
                zIndex="99"
                bgcolor="#fff"
              >
                {items?.programs?.map((it: any, index: number) => {
                  return (
                    <>
                      <Box
                        padding="14px 16px"
                        display="flex"
                        borderRadius="8px"
                        alignItems="center"
                        justifyContent="space-between"
                        gap="20px"
                      >
                        <Typography
                          fontSize="14px"
                          fontWeight="400"
                          width="100px"
                          fontFamily="var(--fontFamiljen)"
                        >
                          {it?.program_name}
                        </Typography>
                        <Box
                          padding="8px"
                          borderRadius="10px"
                          fontSize="14px"
                          bgcolor="var(--themeOrangeLight)"
                          fontWeight="400"
                          fontFamily="var(--fontFamiljen)"
                        >
                          {it?.batch_name}
                        </Box>
                      </Box>
                      {items?.programs?.length - 1 !== index && <hr />}
                    </>
                  );
                })}
              </Box>
            }
          >
            <Typography
              color="var(--black)"
              fontFamily="var(--fontFamiljen)"
              fontStyle="normal"
              fontWeight="400"
              fontSize="14px"
              lineHeight="18px"
            >
              {!items?.programs
                ? null
                : items?.programs[0]?.program_name?.length > 15
                  ? items?.programs[0]?.program_name.substring(0, 15) + "..."
                  : items?.programs[0]?.program_name}
            </Typography>
          </LightTooltip>
        )}
        {items?.programs?.length > 1 && (
          <LightTooltip
            title={
              <Box
                boxShadow="2px 2px 5px grey"
                borderRadius="8px"
                width="220px"
                zIndex="99"
                bgcolor="#fff"
              >
                {items?.programs?.map((it: any, index: number) => {
                  return (
                    <>
                      <Box
                        padding="14px 16px"
                        display="flex"
                        borderRadius="8px"
                        alignItems="center"
                        justifyContent="space-between"
                        gap="20px"
                      >
                        <Typography
                          fontSize="14px"
                          fontWeight="400"
                          width="100px"
                          fontFamily="var(--fontFamiljen)"
                        >
                          {it?.program_name}
                        </Typography>
                        <Box
                          padding="8px"
                          borderRadius="10px"
                          fontSize="14px"
                          bgcolor="var(--themeOrangeLight)"
                          fontWeight="400"
                          fontFamily="var(--fontFamiljen)"
                        >
                          {it?.batch_name}
                        </Box>
                      </Box>
                      {items?.programs?.length - 1 !== index && <hr />}
                    </>
                  );
                })}
              </Box>
            }
          >
            <Typography
              color="var(--themeOrange)"
              fontFamily="var(--fontFamiljen)"
              fontStyle="normal"
              fontWeight="400"
              fontSize="14px"
              lineHeight="18px"
            >
              + {items?.programs?.length - 1}
              {items?.programs?.length - 1 > 1 ? " programs" : " program"}
            </Typography>
          </LightTooltip>
        )}
      </Box>
    );
  };

  const mentorsLearner = (items: LearnerItemsInterface) => {
    const allMentors = items?.mentors?.join(" , ");
    return (
      <Box
        onClick={() => handleRowClicked(items?.id)}
        display="flex"
        alignItems="center"
        gap="5px"
      >
        <Typography
          color="var(--black)"
          fontFamily="var(--fontFamiljen)"
          fontStyle="normal"
          fontWeight="400"
          fontSize="14px"
          lineHeight="18px"
        >
          {items?.mentors?.length > 0 && items?.mentors[0]}
        </Typography>
        {items?.mentors?.length > 1 && (
          <BootstrapTooltip title={allMentors} placement="top">
            <Typography
              color="var(--themeOrange)"
              fontFamily="var(--fontFamiljen)"
              fontStyle="normal"
              fontWeight="400"
              fontSize="14px"
              lineHeight="18px"
            >
              + {items?.mentors?.length - 1}
              {items?.mentors?.length - 1 > 1 ? " mentors" : " mentor"}
            </Typography>
          </BootstrapTooltip>
        )}
      </Box>
    );
  };

  const actionsLearner = (items: LearnerItemsInterface) => {
    return (
      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          alignItems: "center",
        }}
        visibility={visibleId === items?.id ? "visible" : "hidden"}
      >
        <Button
          onClick={() => handleRowClicked(items?.id)}
          sx={{
            color: "var(--themeOrange)",
            fontFamily: "var(--fontFamiljen)",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "18px",
            textTransform: "capitalize",
          }}
          variant="text"
        >
          View detail
        </Button>
      </Box>
    );
  };

  const emailAddressMentor = (items: MentorItemsInterface) => {
    return <EmailTypography>{items?.email}</EmailTypography>;
  };

  const trainingForMentors = (items: MentorItemsInterface) => {
    return (
      <Box display="flex" alignItems="center" gap="5px">
        {items?.programs_with_batches?.length > 1 ? (
          <Typography
            color="var(--black)"
            fontFamily="var(--fontFamiljen)"
            fontStyle="normal"
            fontWeight="400"
            fontSize="14px"
            lineHeight="18px"
          >
            {items?.programs_with_batches?.length !== 1 &&
              items?.programs_with_batches[0]?.program_name?.length > 12
              ? items?.programs_with_batches[0]?.program_name.substring(0, 12) +
              "..."
              : items?.programs_with_batches[0]?.program_name}
          </Typography>
        ) : (
          <LightTooltip
            title={
              <Box
                boxShadow="2px 2px 5px grey"
                borderRadius="8px"
                width="220px"
                zIndex="99"
                bgcolor="#fff"
              >
                {items?.programs_with_batches?.map((it: any, index: number) => {
                  return (
                    <>
                      <Box
                        padding="14px 16px"
                        display="flex"
                        borderRadius="8px"
                        alignItems="center"
                        justifyContent="space-between"
                        gap="20px"
                      >
                        <Typography
                          fontSize="14px"
                          fontWeight="400"
                          width="100px"
                          fontFamily="var(--fontFamiljen)"
                          sx={{ wordBreak: "break-word" }}
                        >
                          {it?.program_name}
                          {/* {it?.program_name?.length > chunkSizeMentor ? (
                            <Box component="span">
                              {splitStringIntoChunks(
                                it?.program_name,
                                chunkSizeMentor
                              ).map((chunk, index) => (
                                <Fragment key={index}>
                                  {chunk}
                                  <br />
                                </Fragment>
                              ))}
                            </Box>
                          ) : (
                            it?.program_name
                          )} */}
                        </Typography>
                        <Box
                          padding="8px"
                          borderRadius="10px"
                          fontSize="14px"
                          bgcolor="var(--themeOrangeLight)"
                          fontWeight="400"
                          fontFamily="var(--fontFamiljen)"
                        >
                          {it?.batch_name?.length >
                            chunkSizeProgressTracking ? (
                            <Box component="span">
                              {splitStringIntoChunks(
                                it?.batch_name,
                                chunkSizeMentorTooltip
                              ).map((chunk, index) => (
                                <Fragment key={index}>
                                  {chunk}
                                  <br />
                                </Fragment>
                              ))}
                            </Box>
                          ) : (
                            it?.batch_name
                          )}
                        </Box>
                      </Box>
                      {items?.programs_with_batches?.length - 1 !== index && (
                        <hr />
                      )}
                    </>
                  );
                })}
              </Box>
            }
          >
            <Typography
              color="var(--black)"
              fontFamily="var(--fontFamiljen)"
              fontStyle="normal"
              fontWeight="400"
              fontSize="14px"
              lineHeight="18px"
            >
              {items?.programs_with_batches?.[0]?.program_name?.length > 15
                ? items?.programs_with_batches?.[0]?.program_name.substring(
                  0,
                  15
                ) + "..."
                : items?.programs_with_batches?.[0]?.program_name}
            </Typography>
          </LightTooltip>
        )}

        {items?.programs_with_batches?.length > 1 && (
          <LightTooltip
            title={
              <Box
                boxShadow="2px 2px 5px grey"
                borderRadius="8px"
                width="220px"
                zIndex="99"
                bgcolor="#fff"
              >
                {items?.programs_with_batches?.map((it: any, index: number) => {
                  return (
                    <>
                      <Box
                        padding="14px 16px"
                        display="flex"
                        borderRadius="8px"
                        alignItems="center"
                        justifyContent="space-between"
                        gap="20px"
                      >
                        <Typography
                          fontSize="14px"
                          fontWeight="400"
                          width="100px"
                          fontFamily="var(--fontFamiljen)"
                        >
                          {it?.program_name?.length > chunkSizeMentorTooltip ? (
                            <Box component="span">
                              {splitStringIntoChunks(
                                it?.program_name,
                                chunkSizeMentorTooltip
                              ).map((chunk, index) => (
                                <Fragment key={index}>
                                  {chunk}
                                  <br />
                                </Fragment>
                              ))}
                            </Box>
                          ) : (
                            it?.program_name
                          )}
                        </Typography>
                        <Box
                          padding="8px"
                          borderRadius="10px"
                          fontSize="14px"
                          bgcolor="var(--themeOrangeLight)"
                          fontWeight="400"
                          fontFamily="var(--fontFamiljen)"
                        >
                          {it?.batch_name?.length > chunkSizeMentor ? (
                            <Box component="span">
                              {splitStringIntoChunks(
                                it?.batch_name,
                                chunkSizeMentorTooltip
                              ).map((chunk, index) => (
                                <Fragment key={index}>
                                  {chunk}
                                  <br />
                                </Fragment>
                              ))}
                            </Box>
                          ) : (
                            it?.batch_name
                          )}
                        </Box>
                      </Box>
                      {items?.programs_with_batches?.length - 1 !== index && (
                        <hr />
                      )}
                    </>
                  );
                })}
              </Box>
            }
          >
            <Typography
              color="var(--themeOrange)"
              fontFamily="var(--fontFamiljen)"
              fontStyle="normal"
              fontWeight="400"
              fontSize="14px"
              lineHeight="18px"
            >
              + {items?.programs_with_batches?.length - 1}
              {items?.programs_with_batches?.length - 1 > 1
                ? " programs"
                : " program"}
            </Typography>
          </LightTooltip>
        )}
      </Box>
    );
  };

  const actionsMentor = (items: MentorItemsInterface) => {
    return (
      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          alignItems: "center",
          justifyContent: "flex-end",
          width: "100%",
        }}
        visibility={visibleId === items.id ? "visible" : "hidden"}
      >
        <IconButton
          disabled={!filteredPermission?.isDelete ? true : false}
          sx={{
            padding: "0",
          }}
          onClick={() => handleDeleteMentor(items)}
        >
          <img src={deleteIcon} alt="deleteIcon" />
        </IconButton>
        <IconButton
          disabled={!filteredPermission?.isEdit ? true : false}
          aria-label="edit"
          color="default"
          onClick={() => handleEditMentor(items.id)}
        >
          <EditIcon fontSize="small" />
        </IconButton>
      </Box>
    );
  };

  const emailOtherMembers = (items: OtherMembersItemsInterface) => {
    return (
      <Typography
        sx={{
          color: "var(--emailTextColor)",
          fontFamily: "var(--fontFamiljen)",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "14px",
          lineHeight: "18px",
        }}
      >
        {items?.email}
      </Typography>
    );
  };

  const assignRolesOtherMembers = (items: OtherMembersItemsInterface) => {
    return (
      <Typography
        color="var(--black)"
        fontFamily="var(--fontFamiljen)"
        fontStyle="normal"
        fontWeight="400"
        fontSize="14px"
        lineHeight="18px"
      >
        {items?.role}
      </Typography>
    );
  };

  const otherMembersActions = (items: OtherMembersItemsInterface) => {
    return (
      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          alignItems: "center",
          justifyContent: "flex-end",
          width: "100%",
        }}
        visibility={visibleId === items.id ? "visible" : "hidden"}
      >
        <IconButton
          disabled={
            !filteredPermission?.isDelete ||
              (isMainCompanyAdmin &&
                otherMembersData?.data?.assigneeList?.[
                  otherMembersData?.data?.assigneeList?.length - 1
                ]?.email === items?.email) ||
              !isMainCompanyAdmin
              ? true
              : false
          }
          sx={{
            padding: "0",
          }}
        >
          <img
            onClick={() => handleOtherMemberDialog(items.id)}
            src={deleteIcon}
            alt="deleteIcon"
          />
        </IconButton>
        <IconButton
          disabled={
            !filteredPermission?.isEdit ||
              (isMainCompanyAdmin &&
                otherMembersData?.data?.assigneeList?.[
                  otherMembersData?.data?.assigneeList?.length - 1
                ]?.email === items?.email) ||
              (!isMainCompanyAdmin &&
                otherMembersData?.data?.assigneeList?.[
                  otherMembersData?.data?.assigneeList?.length - 1
                ]?.email === items?.email)
              ? true
              : false
          }
          aria-label="edit"
          color="default"
          onClick={() => handleEditOtherMember(items.id)}
        >
          <EditIcon fontSize="small" />
        </IconButton>
      </Box>
    );
  };

  const columnsLearner = [
    {
      name: "NAME",
      selector: (items: any) => items.name,
    },
    {
      name: "EMAIL ADDRESS ",
      selector: (items: any) => emailAddressLearner(items),
    },
    {
      name: "ASSIGNED PROGRAMS",
      selector: (items: any) => assignedProgramsLearner(items),
    },
    {
      name: "MENTOR",
      width: "240px",
      selector: (items: any) => mentorsLearner(items),
    },
    {
      name: " ",
      cell: (items: any) => actionsLearner(items),
    },
  ];

  const columnsMentor: any = [
    {
      name: "NAME",
      selector: (items: any) =>
        (items?.first_name + " " + items?.last_name)?.length > 40
          ? (items?.first_name + " " + items?.last_name)?.slice(0, 40) + "..."
          : items?.first_name + " " + items?.last_name,
    },
    {
      name: "EMAIL ADDRESS ",
      width: "280px",
      selector: (items: any) => emailAddressMentor(items),
    },
    {
      name: "TRAINING FOR",
      selector: (items: any) => trainingForMentors(items),
    },
    {
      name: "ACTIONS",
      right: true,
      cell: (items: any) => actionsMentor(items),
    },
  ];

  const columnsOtherMember: any = [
    {
      name: "NAME",
      selector: (items: any) => items?.name,
    },
    {
      name: "EMAIL ADDRESS ",
      selector: (items: any) => emailOtherMembers(items),
    },
    {
      name: "ASSIGN ROLE",
      selector: (items: any) => assignRolesOtherMembers(items),
    },
    {
      name: "ACTIONS",
      right: true,
      cell: (items: any) => otherMembersActions(items),
    },
  ];

  const handleAddTask = () => {
    setItemClickedId("");
    // setSingleLearnerDetail("");
    setSingleMentorDetail("");
    setFilteredSingleMember({});
    setOpenLearnerDrawer(true);
  };

  const handleActions = (dataTable: any) => {
    setVisibleId(dataTable.id);
  };

  const handleActionLeave = () => {
    setVisibleId("");
  };

  const handleDeleteAll: any = async (value: any) => {
    const selectedIds = value?.selectedRows?.map((i: any) => i.id);
    setDeleteLearnerIdArray(selectedIds);
    if (selectedIds.length) {
      setShowDelete(true);
    } else {
      setShowDelete(false);
    }
  };

  const handleDeleteLearner = async () => {
    const reqObj = {
      url: "learner-delete",
      body: {
        org_id: organizationId,
        learner_ids: deleteLearnerIdArray,
      },
    };
    setConfirmBox(true);
    try {
      const resp = await deleteLearners(reqObj).unwrap();
      if (resp.code === 3032) {
        setConfirmBox(false);
        dispatch(
          openAlert({
            message: resp.message,
            severity: "success",
          })
        );
        setUpdateList(!updateList);
        setDeleteLearnerIdArray([]);
        refetch();
        learnerRefetch();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleDeleteMentor = async (items: any) => {
    setDeleteMentorId({
      id: items.id,
      name: items.first_name + " " + items.last_name,
    });
    // if (items?.isAssigned) {
    setOpenDeleteModal(true);
    // } else {
    //   setConfirmBox(true);
    // }
  };

  const handleDeleteMentorDirectly = async () => {
    const reqObj = {
      url: "mentor-delete",
      body: {
        mentor_id: deleteMentorId?.id,
      },
    };
    try {
      const resp = await deleteMentors(reqObj).unwrap();
      if (resp.code === 3031) {
        dispatch(
          openAlert({
            message: resp.message,
            severity: "success",
          })
        );
        setConfirmBox(false);
        setUpdateList(!updateList);
        refetch();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleOtherMemberDialog = (id: string) => {
    setConfirmBox(true);
    setOtherMembersDeleteId(id);
  };

  const handleDeleteOtherMember = async () => {
    const reqObj = {
      url: "remove-assignee",
      roleId: otherMembersDeleteId,
    };
    try {
      const resp = await removeAssignee(reqObj).unwrap();
      if (resp.code === 3063) {
        setConfirmBox(false);
        dispatch(
          openAlert({
            message: resp.message,
            severity: "success",
          })
        );
        setUpdateList(!updateList);
        refetch();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const learnerConfirmDailoge = () => {
    setConfirmBox(true);
  };

  const mentorConfirmDailoge = () => {
    setConfirmBox(true);
  };

  const handlePageChange = (event: any, pageNumber: number) => {
    setCurrentPage(pageNumber);
    setOffset((pageNumber - 1) * limit);
  };

  const handlePageChangeMentor = (event: any, pageNumber: number) => {
    setCurrentPageMentor(pageNumber);
    setOffsetMentor((pageNumber - 1) * limit);
  };

  const handlePageChangeMember = (event: any, pageNumber: number) => {
    // setCurrentPageMentor(pageNumber);
    setCurrentPageOtherMember(pageNumber);
    setOffsetOtherMember((pageNumber - 1) * limit);
  };

  const handleRowClicked = async (id: any) => {
    setItemClickedId(id);
    setLearnerDetailDrawer(true);
  };

  const handleInputDebounceChange = debounce(changeSearchValueWithOffset, 1000);

  const handleDeleteDisable = () => {
    if (
      !filteredPermission?.isDelete ||
      data?.data?.learners?.length === 0 ||
      mentorData?.data?.mentors?.length === 0
    ) {
      return true;
    } else return false;
  };

  const handleEditDisable = () => {
    if (alignment !== Other_Member && !programsList?.data?.total) return true;
    else if (!filteredPermission?.isEdit) return true;
    else return false;
  };

  const toggleButtonColorLearner = useMemo(
    () => (alignment === Learner ? "#fff" : "var(--inerTextColor)"),
    [alignment]
  );
  const toggleButtonBgColorLearner = useMemo(
    () => (alignment === Learner ? "var(--themeOrange)" : "var(--lightGray)"),
    [alignment]
  );

  const toggleButtonColorMentor = useMemo(
    () => (alignment === Mentor ? "#fff" : "var(--inerTextColor)"),
    [alignment]
  );
  const toggleButtonBgColorMentor = useMemo(
    () => (alignment === Mentor ? "var(--themeOrange)" : "var(--lightGray)"),
    [alignment]
  );

  const toggleButtonColorOtherMember = useMemo(
    () => (alignment === Other_Member ? "#fff" : "var(--inerTextColor)"),
    [alignment]
  );
  const toggleButtonBgColorOtherMember = useMemo(
    () =>
      alignment === Other_Member ? "var(--themeOrange)" : "var(--lightGray)",
    [alignment]
  );

  const displayDeleteButtonLearner = useMemo(
    () => (showDelete ? "flex" : "none"),
    [showDelete, alignment]
  );

  const filteredPermissionCursor = () => {
    return filteredPermission?.isEdit ? "pointer" : "not-allowed";
  };

  const addingTextForUserManagement = useMemo(() => {
    if (alignment === Mentor) {
      return Mentor;
    } else if (alignment === Other_Member) {
      return "Member";
    } else return Learner;
  }, [alignment]);

  const deleteLearnerLoadingText = useMemo(
    () => (deleteLearnerLoading ? "Deleting..." : "Delete"),
    [deleteLearnerLoading]
  );

  const dataTableColumns = () => {
    if (alignment === Learner) {
      return columnsLearner;
    } else if (alignment === Mentor) {
      return columnsMentor;
    } else return columnsOtherMember;
  };

  const dataTableData = () => {
    if (alignment === Learner) {
      return data?.data?.learners;
    } else if (alignment === Mentor) {
      return mentorData?.data?.mentors;
    } else return otherMembersData?.data?.assigneeList;
  };

  const selectedRows = () => {
    if (alignment === Learner) {
      return true;
    } else return false;
  };

  const handlePaginationChange = (e: any, pageNumber: number) => {
    if (alignment === Learner) {
      handlePageChange(e, pageNumber);
    } else if (alignment === Other_Member) {
      handlePageChangeMember(e, pageNumber);
    } else handlePageChangeMentor(e, pageNumber);
  };

  const paginationCount = () => {
    if (alignment === Learner) {
      return Math.ceil(data?.data?.total / limit);
    } else if (alignment === Other_Member) {
      return Math.ceil(otherMembersData?.data?.totalAssignees / limit);
    } else return Math.ceil(mentorData?.data?.total / limit);
  };

  const paginationPage = () => {
    if (alignment === Learner) {
      return currentPage;
    } else if (alignment === Other_Member) {
      return currentPageOtherMember
    } else return currentPageMentor;
  };

  const alertBoxOnclick = () => {
    if (alignment === Mentor) {
      handleDeleteMentorDirectly();
    } else if (alignment === Other_Member) {
      handleDeleteOtherMember();
    } else handleDeleteLearner();
  };

  const alertBoxTitle = () => {
    if (alignment === Mentor) {
      return "Delete Mentor?";
    } else if (alignment === Other_Member) {
      return "Delete Member?";
    } else return "Delete Learner?";
  };

  const alertBoxText = () => {
    if (alignment === Mentor) {
      return mentorDeleteText;
    } else if (alignment === Other_Member) {
      return memberDeleteText;
    } else if (alignment === Learner && deleteLearnerIdArray?.length > 1) {
      return learnerMultipleDeleteText;
    } else {
      return learnerDeleteText;
    }
  };

  const isLearnerAvailable = () => {
    if (alignment === Learner && data?.data?.total < 1) {
      return true;
    }
    return false;
  };

  const isMentorAvailable = () => {
    if (alignment === Mentor && mentorData?.data?.total < 1) {
      return true;
    }
    return false;
  };
  const isOtherMemberAvailable = () => {
    if (
      alignment === Other_Member &&
      otherMembersData?.data?.assigneeList?.length < 1
    ) {
      return true;
    }
    return false;
  };

  const learnerMentorAvailable = () => {
    if (
      (alignment === Learner && data?.data?.total < 1) ||
      (alignment === Mentor && mentorData?.data?.total < 1)
    ) {
      return true;
    }
    return false;
  };

  const drawerFunction = () => {
    if (alignment === Mentor) {
      return (
        <MentorDrawer
          setUpdateList={setUpdateList}
          updateList={updateList}
          singleMentorDetail={singleMentorDetail}
          onHide={() => setOpenLearnerDrawer(false)}
        />
      );
    } else if (alignment === Other_Member) {
      return (
        <OtherMember
          setUpdateList={setUpdateList}
          updateList={updateList}
          filteredSingleMember={filteredSingleMember}
          onHide={() => setOpenLearnerDrawer(false)}
        />
      );
    } else {
      return (
        <LearnerDrawer
          singleLearnerDetail={singleLearnerDetail?.data[0]}
          onHide={() => setOpenLearnerDrawer(false)}
        />
      );
    }
  };

  return (
    <>
      <BreadCrumbs
        sx={{
          fontSize: "14px",
          color: "var(--InputMessage)",
          fontWeight: "400",
          lineHeight: "18px",
          marginTop: "16px",
          fontFamily: "var(--fontFamiljen)",
        }}
        breadCrumbsName="User Management"
        navigateTo="user-management"
      />
      <Typography
        fontSize="1.5rem"
        fontFamily="var(--fontMulish)"
        fontWeight="800"
        variant="h1"
        marginTop="1rem"
        marginBottom={2}
      >
        User Management
      </Typography>
      <ToggleButtonGroup
        color="primary"
        value={alignment}
        sx={{
          height: "40px",
          width: "302px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          padding: "0px 2px",
          marginBottom: "20px",
          gap: "8px",
          background: "var(--lightGray)",
          borderRadius: "5px",
        }}
        exclusive
        aria-label="Platform"
      >
        <ToggleButton
          style={{
            backgroundColor: toggleButtonBgColorLearner,
            color: toggleButtonColorLearner,
            width: "82px",
          }}
          sx={toggelBtnClass}
          value={Learner}
          onClick={(e: any) => handleChange(e, Learner)}
        >
          Learners
        </ToggleButton>
        <ToggleButton
          style={{
            backgroundColor: toggleButtonBgColorMentor,
            color: toggleButtonColorMentor,
            width: "79px",
          }}
          onClick={(e: any) => handleChange(e, Mentor)}
          sx={toggelBtnClass}
          value={Mentor}
        >
          Mentors
        </ToggleButton>
        <ToggleButton
          style={{
            backgroundColor: toggleButtonBgColorOtherMember,
            color: toggleButtonColorOtherMember,
            width: "121px",
          }}
          sx={toggelBtnClass}
          value={Other_Member}
          onClick={(e: any) => handleChange(e, Other_Member)}
        >
          Other Members
        </ToggleButton>
      </ToggleButtonGroup>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          background: "var(--lightGray)",
          padding: ".625rem 0",
          position: "relative",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <SearchIcon
            sx={{
              position: "absolute",
              top: "32%",
              left: "1.925rem",
              opacity: "0.5",
            }}
          />
          <StyledSearchInput
            placeholder="Search"
            // value={management}
            style={{
              fontFamily: "var(--fontFamiljen)",
              width: "25.43rem",
              height: "36px",
              fontSize: "14px",
              marginLeft: "16px",
              fontWeight: "400",
              fontStyle: "normal",
              color: "var(--dashBoardEmptyText)",
            }}
            // name="search"
            type="search"
            // onChange={(e: any) => handleInputDebounceChange(e)}
            onChange={handleInputDebounceChange}
          />
          <FilterBox
            filteredPermission={filteredPermission}
            programIdArray={programIdArray}
            mentorIdArray={mentorIdArray}
            membersIdArray={membersIdArray}
            data={data?.data}
            mentorData={mentorData?.data}
            alignment={alignment}
            setMentorIdArray={setMentorIdArray}
            setProgramIdArray={setProgramIdArray}
            programsList={programsList}
            setMembersIdArray={setMembersIdArray}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Button
            disabled={handleDeleteDisable()}
            variant="text"
            color="primary"
            onClick={
              alignment === Mentor
                ? mentorConfirmDailoge
                : learnerConfirmDailoge
            }
            sx={{
              marginRight: "24px",
              textTransform: "capitalize",
              fontWeight: `var(--font500)`,
              fontFamily: `var(--fontFamiljen)`,
              display: displayDeleteButtonLearner,
              justifySelf: "end",
              color: "red",
            }}
          >
            {deleteLearnerLoadingText}
          </Button>

          <Button
            variant="text"
            color="primary"
            onClick={() => handleAddTask()}
            disabled={handleEditDisable()}
            sx={{
              marginRight: "4px",
              textTransform: "capitalize",
              fontWeight: `var(--font500)`,
              fontFamily: `var(--fontFamiljen)`,
              cursor: filteredPermissionCursor,
            }}
          >
            Add {addingTextForUserManagement}
            <AddIcon fontSize="small" />
          </Button>
        </Box>
      </Box>
      {isLearnerAvailable() ? (
        <NoModulesFound
          hideBtn="hideBtn"
          heading="There are currently no learners available."
          imgSrc={NoLearnerFound}
        />
      ) : null}

      {isMentorAvailable() ? (
        <NoModulesFound
          hideBtn="hideBtn"
          heading="There are currently no Mentors available."
          imgSrc={NoMentorFound}
        />
      ) : null}

      {isOtherMemberAvailable() ? (
        <NoModulesFound
          hideBtn="hideBtn"
          heading=" There are currently no Other Members available."
          imgSrc={NoMentorFound}
        />
      ) : null}

      {mentorsLoading ||
        learnersLoading ||
        otherMembersLoading ||
        learnerFetch ||
        mentorFetch ||
        otherFetch ? (
        <SkeletonTable
          sx={{ width: "100%", height: "20rem", padding: "1rem 0rem" }}
        />
      ) : filteredPermission?.isView ? (
        <>
          <DataTable
            columns={dataTableColumns()}
            data={dataTableData()}
            highlightOnHover
            responsive
            selectableRowsHighlight
            onRowClicked={(e: any) => {
              alignment === Learner && handleRowClicked(e.id);
            }}
            pointerOnHover
            onRowMouseEnter={handleActions}
            onRowMouseLeave={handleActionLeave}
            selectableRows={selectedRows()}
            onSelectedRowsChange={handleDeleteAll}
            noDataComponent={false}
          />
          {learnerMentorAvailable() && <></>}
          {data?.data?.total >= 1 || mentorData?.data?.total >= 1 ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                padding: "0.5rem 0rem",
                backgroundColor: "var(--lightGray)",
              }}
            >
              <Pagination
                count={paginationCount()}
                page={paginationPage()}
                onChange={(e: React.ChangeEvent<unknown>, pageNumber: number) =>
                  handlePaginationChange(e, pageNumber)
                }
                color="standard"
                shape="rounded"
              />
            </Box>
          ) : null}
        </>
      ) : (
        <NoModulesFound
          hideBtn="hideBtn"
          heading="Don't have permission"
          imgSrc={NoMentorFound}
        />
      )}
      {openLearnerDrawer && (
        <UserManagementDrawer
          openLearnerDrawer={openLearnerDrawer}
          onHide={() => setOpenLearnerDrawer(false)}
        >
          {drawerFunction()}
        </UserManagementDrawer>
      )}
      {learnerDetailDrawer && (
        <LearnerDetailDrawer
          learnerDetailDrawer={learnerDetailDrawer}
          singleLearnerDetail={singleLearnerDetail?.data[0]}
          singleLearnerLoading={singleLearnerLoading}
          onHide={() => {
            setLearnerDetailDrawer(false);
            // setItemClickedId("");
          }}
          setOpenLearnerDrawer={setOpenLearnerDrawer}
        />
      )}
      <AlertBox
        confirmBox={confirmBox}
        setConfirmBox={setConfirmBox}
        onClick={alertBoxOnclick}
        title={alertBoxTitle}
        message={<p style={{ margin: "0" }}>{alertBoxText()}</p>}
        nameBtn1="No Cancel"
        nameBtn2="Yes Delete"
        imgSrc={Delete}
      />
      {openDeleteModal && (
        <DeleteMentorDrawer
          deleteMentorId={deleteMentorId}
          updateList={updateList}
          setUpdateList={setUpdateList}
          openDeleteModal={openDeleteModal}
          onHide={() => setOpenDeleteModal(false)}
        />
      )}
    </>
  );
};

export default UserManagement;

export const EmailTypography = ({ children }: any) => {
  return (
    <Typography
      sx={{
        color: "var(--emailTextColor)",
        fontFamily: "var(--fontFamiljen)",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "18px",
      }}
    >
      {children}
    </Typography>
  );
};
